import { customElement, bindable } from 'aurelia-framework';
import { SecurityLevels } from '../../../../../config';
import { SecurityLevel } from '../../../../../interfaces/enums/securityLevel';

@customElement('security-level-icon')
export class SecurityLevelIcon {
    @bindable securityLevel: SecurityLevel;

    getSecurityLevelTooltip(securityLevel: SecurityLevel) : string {
        return "Security Level : " + SecurityLevels[securityLevel];
    }
}
