import {autoinject} from 'aurelia-framework';
import {BaseViewModel} from '../../../common/BaseViewModel';
import {IUserTrendChannelsReducerState, rootState} from '../../../../reducers';
import {
  axisPropertyChanged,
  editTrendChannel,
  exportDatePropertyChanged,
  exportPropertyChanged,
  exportToggleAllSiteChannels,
  exportToggleSiteChannel, securityLevelChanged,
  selectedAxisAutoScaleChanged,
  selectedAxisLocationChanged,
  selectedAxisTypeChanged,
  selectedChannelChanged,
  selectedOperatorChanged,
  selectedVisibilityChanged,
  setTrendChannelColor,
  showAlarmsChanged,
  showGapsChanged,
  showRawChanged,
  toggleExpandMenuOnEntity,
  toggleExport,
  toggleSelectedTrendChannels,
  toggleTrendEditDialog,
  toggleTrendNewGroupDialog,
  trendGroupChanged,
  trendGroupPropertyChanged
} from '../../../../actions';
import {HistorianService} from '../../../../services/historianService';
import {
  ChannelTypes,
  IAlarmLogExtended,
  IDateRange,
  IProduct,
  IRequestState,
  ISite,
  ISiteChannel,
  ITrendAxis,
  ITrendChannel,
  ITrendChannelDetails,
  ITrendGroup,
  IUnit
} from '../../../../interfaces';
import {
  assureArray,
  asyncEntityIsFetched,
  distinct,
  emptyArray,
  ensureNumber,
  getAllEntities,
  getAsyncEntitiesByAsyncArray,
  getAsyncEntity,
  getEntity,
  getEntityOrDefault,
  getEntityOrUndefined,
  isArray,
  isEmpty,
  isFiniteNumber,
  isNone,
  isSameDay,
  isSomething,
  leftPadNumber,
  mapFetchedAsyncEntity,
  orderByPredicate,
  removeNoneFromArray,
  setBeginningOfDay,
  setEndOfDay,
  toObject
} from '../../../../utility';
import {getLogger} from 'aurelia-logging';
import {menuIsOpenForEntity} from '../../../../types/index';
import {
  ChannelService,
  ControllerService,
  UnitService,
  UserTrendChannelService,
  UserTrendGroupService
} from '../../../../services/index';
import {
  getTimezoneForSiteSelector,
  selectCurrentSite,
  selectProductsOrEmptyArray,
  selectUnitsOrEmptyArray
} from '../../../common/index';
import {ITrendOperator, trendOperators} from '../../../../utility/trendHelpers';
import {createSelector} from 'reselect';
import {selectChannelsForCurrentSite} from '../../selectors';
import {IAsyncEntity} from '../../../../types/objectDictionary';
import {DateRange} from '../../../../interfaces/enums/dateRange';
import {IDataProcessChannel} from '../../../../models';
import {IGraphExport} from '../../../../interfaces/entity/iGraphExport';
import {GraphExportService} from '../../../../services/graphExportService';
import {DeepPartial} from '../../../../types/deepPartial';
import {EventAggregator} from 'aurelia-event-aggregator';
import './sitedetailsgraph.css';
import {ColorRotator} from '../../../../utility/application/colorRotator';
import {NotificationService} from '../../../../services/notificationService';
import {SecurityLevel} from "../../../../interfaces/enums/securityLevel";
import {getSession} from "../../../../config/sessionService";

interface ISiteChannelTrendState {
  utcFrom: Date;
  utcTo: Date;
  queryFrom: Date;
  queryTo: Date;
  interval: number;
  showGaps: boolean;
  showRaw: boolean;
  operatorInterval: number;
  fromToIsSameDate: boolean;
  securityLevel: SecurityLevel | undefined;

  trendChannelGroups: ITrendGroup[];
  currentTrendGroup?: ITrendGroup;
  currentlyEditingTrendGroup?: ITrendGroup;

  trendChannels: ITrendChannelDetails[];
  availableChannels: ISiteChannel[];
  availableChannelsMap: {};

  site: ISite | undefined;
  siteId: number;
  currentlyEditingTrend: DeepPartial<ITrendChannel> | undefined;
  trendIdsToEdit: number[] | undefined;
  showTrendChannelNewGroupEditor: boolean;

  operators: ITrendOperator[];
  units: IUnit[];
  operatorsMap: {};
  axisTypes: {};
  axisLocations: {};
  products: {};
  menuOpenForEntity?: menuIsOpenForEntity;
  loadingTrendData: boolean;
  graphExport?: IGraphExport;

  exportFormatsMap: {};
  exportResolutionsMap: {};
  exportQueryOperatorsMap: {};
  showExportDialog: boolean;
  graphExportRequest: IRequestState;
  graphhExportToggleAll: boolean;
  hasSelectedTrendChannels: boolean;
  hasSelectedAllTrendChannels: boolean;
  selectedTrendChannelIds: number[];

  timezone?: string;
  alarmLogs?: IAlarmLogExtended[];
  showAlarms: boolean;
}

const exportFormats = {
  0: 'CSV',
  1: 'Excel'
};

const exportResolutions = {
  0: '5 min',
  1: '1 hour'
};

const exportQueryOperators = {
  0: 'Last',
  1: 'Min',
  2: 'Max',
  3: 'Mean',
  4: 'Delta',
  5: 'StdDev',
  6: 'Count',
  7: 'Sum'
};

const trendChannelSelector = createSelector<
  rootState,
  number,
  IUserTrendChannelsReducerState,
  ITrendChannel[]
>(
  state => state.dataanalysis.currentTrendGroupId || 0,
  state => state.userTrendChannels,
  (currentTrendGroupId, userTrends) =>
    userTrends[currentTrendGroupId] || emptyArray
);

const trendSiteChannelSelector = createSelector<
  rootState,
  IAsyncEntity<ISite>,
  ISiteChannel[],
  IProduct[],
  ITrendChannel[],
  IUnit[],
  number[],
  ITrendChannelDetails[]
>(
  selectCurrentSite,
  selectChannelsForCurrentSite,
  selectProductsOrEmptyArray,
  trendChannelSelector,
  selectUnitsOrEmptyArray,
  state => state.dataanalysis.selectedTrendChannels,
  (site, channels, products, trendChannels, units, selectedTrendChannels) =>
    orderByPredicate(trendChannels.map<ITrendChannelDetails>(tc => {
      const siteChannel = channels.find(sc => sc.channelId === tc.channelId);

        const product = products.find(
          p => !!siteChannel && p.productId === siteChannel.productId
        );
        const unit = units.find(u => u.unitId === tc.unitId);
        let unitWithoutInterval: IUnit | undefined = undefined;

        if (unit)
          unitWithoutInterval = units.find(
            u => u.unitId === unit.unitIdWithoutInterval
          );

        const operator = trendOperators.find(o => o.id === tc.operatorId);
        return {
          ...tc,
          siteName: getEntityOrDefault(
            mapFetchedAsyncEntity(site, s => s.alias),
            ''
          ),
          channelName: siteChannel ? siteChannel.alias : '',
          color:
            tc.color ||
            (siteChannel && siteChannel.color) ||
            (product && product.color) ||
            '',
          unitName: unit ? unit.symbol : '',
          unitNameSum: unitWithoutInterval ? unitWithoutInterval.symbol : '',
          operator: operator ? operator.value : '',
          sortIndex: siteChannel ? siteChannel.sortIndex : undefined,
          unit,
          selected: !isNone(selectedTrendChannels.find(t => t === tc.trendKey))
        };
      }),
      c => leftPadNumber(c.sortIndex || 0, 3) + "_" + c.channelName, 'asc'
    )
);

const mapState = (siteId: number) => (
  state: rootState
): ISiteChannelTrendState => {
  const { dataanalysis } = state;
  const { trendOperators, trendAxisLocations, trendAxisTypes } = dataanalysis;
  const site = getEntityOrUndefined(selectCurrentSite(state));
  const siteChannels = getEntityOrDefault(
    getAsyncEntitiesByAsyncArray(
      getAsyncEntity(state.channels.siteChannelsForSites, siteId),
      state.channels.byId
    ),
    emptyArray
  );
  const asyncProducts = getAllEntities(state.products);
  const products = asyncEntityIsFetched(asyncProducts)
    ? getEntity(asyncProducts)
    : emptyArray;
  const asyncUnits = getAllEntities(state.units);
  const units = asyncEntityIsFetched(asyncUnits)
    ? getEntity(asyncUnits)
    : emptyArray;
  const currentTrendGroup = (state.userTrendGroups[siteId] || emptyArray).find(
    group => group.userTrendGroupId === dataanalysis.currentTrendGroupId
  );

  const trendChannels = state.dataanalysis.securityLevel === undefined 
    ? trendSiteChannelSelector(state) 
    : trendSiteChannelSelector(state).filter(c => c.securityLevel === state.dataanalysis.securityLevel);

  if (currentTrendGroup) currentTrendGroup.trendChannels = trendChannels;

  const operatorsMap = toObject(trendOperators, t => t.id);
  const axisLocationsMap = toObject(trendAxisLocations, t => t.id);
  const axisTypesMap = toObject(trendAxisTypes, t => t.id);

  const trendChannelGroups = state.userTrendGroups[siteId];

  //const alarmLogsExtendedKey = `${siteId} ${state.dataanalysis.utcFrom.toJSON()} ${state.dataanalysis.utcTo.toJSON()} ${trendChannels.map(tc => tc.siteChannelId).join()}`;
  const alarmLogs = site
    ? getEntityOrUndefined(
        getAsyncEntity(state.alarmLogs.bySiteAndDate, 'value')
      )
    : undefined;
  const colorRotator = new ColorRotator();
  const siteChannelsWithColors = siteChannels.map(sc => {
    return {
      ...sc,
      color: sc.color ? sc.color : colorRotator.color
    };
  });

  return {
    utcFrom: state.dataanalysis.utcFrom,
    utcTo: state.dataanalysis.utcTo,
    queryFrom: state.dataanalysis.queryFrom,
    queryTo: state.dataanalysis.queryTo,
    fromToIsSameDate: isSameDay(
      state.dataanalysis.utcFrom,
      state.dataanalysis.utcTo
    ),
    interval: state.dataanalysis.interval,
    operatorInterval: state.dataanalysis.operatorInterval,
    showGaps: state.dataanalysis.showGaps,
    showRaw: state.dataanalysis.showRaw,
    trendChannels,
    trendChannelGroups,
    currentTrendGroup,
    currentlyEditingTrendGroup: state.dataanalysis.currentlyEditingTrendGroup,
    securityLevel: state.dataanalysis.securityLevel,

    availableChannels: siteChannelsWithColors,
    availableChannelsMap: toObject(siteChannelsWithColors, ch => ch.channelId),

    site,
    siteId,

    currentlyEditingTrend: dataanalysis.currentlyEditingTrend,
    trendIdsToEdit: state.dataanalysis.currentlyEditingTrendIds,
    showTrendChannelNewGroupEditor:
      state.dataanalysis.toggleTrendNewGroupDialog,

    operators: trendOperators,

    operatorsMap,
    axisLocations: axisLocationsMap,
    axisTypes: axisTypesMap,
    products,
    units,
    menuOpenForEntity: state.application.menuIsOpenForEntity,
    loadingTrendData: !isNone(state.dataanalysis.currentRequestId),

    graphExport: state.dataanalysis.graphExport,
    graphhExportToggleAll:
      (state.dataanalysis.graphExport &&
        siteChannelsWithColors.length ===
          state.dataanalysis.graphExport.siteChannelIds.length) ||
      false,
    exportFormatsMap: exportFormats,
    exportResolutionsMap: exportResolutions,
    exportQueryOperatorsMap: exportQueryOperators,
    showExportDialog: state.dataanalysis.showExportDialog,
    graphExportRequest: state.dataanalysis.graphExportRequest,
    hasSelectedTrendChannels: !isEmpty(dataanalysis.selectedTrendChannels),
    hasSelectedAllTrendChannels:
      dataanalysis.selectedTrendChannels.length === trendChannels.length &&
      !isEmpty(dataanalysis.selectedTrendChannels),
    selectedTrendChannelIds: dataanalysis.selectedTrendChannels,
    timezone: getTimezoneForSiteSelector(state),
    alarmLogs,
    showAlarms: dataanalysis.showAlarms
  };
};

@autoinject()
export class SiteDetailsGraph extends BaseViewModel<ISiteChannelTrendState> {
  rangeUnit: string = 'm';
  interval: number = 5;
  initialDataLoad: boolean = false;
  currentGroup?: ITrendGroup;

  operatorRangeUnits = [
    { value: 'm', name: 'Minutes', id: 0 },
    { value: 'h', name: 'Hours', id: 1 },
    { value: 'd', name: 'Days', id: 2 },
    { value: 'w', name: 'Weeks', id: 3 }
  ];

  dateRangeItems = [
    { name: 'Today', value: DateRange.Today },
    { name: 'Yesterday', value: DateRange.Yesterday },
    { name: 'Last 7 days', value: DateRange.Last7days },
    { name: 'Last 30 days', value: DateRange.Last30Days },
    { name: 'This month', value: DateRange.ThisMonth },
    { name: 'Last month', value: DateRange.LastMonth }
  ];

  constructor(
    private historianService: HistorianService,
    private userTrendChannelService: UserTrendChannelService,
    private userTrendGroupService: UserTrendGroupService,
    private channelService: ChannelService,
    private controllerService: ControllerService,
    private unitService: UnitService,
    private graphExportService: GraphExportService,
    private eventAggregator: EventAggregator,
    private notificationService: NotificationService
  ) {
    super(getLogger(SiteDetailsGraph.name));
  }

  activate({ id }: { id: string }) {
    this.attachMapState(mapState(ensureNumber(id)));
    this.unitService.fetchUnitsAsync();
    this.getInitialTrendGroupsAndData();
  }

  showCustomDateDialog = false;
  customDateFrom = new Date();
  customDateTo = new Date();
  customInterval = 0;
  customOperatorInterval = 0;

  showCustomDateDialogModal() {
    this.customDateFrom = this.state.utcFrom;
    this.customDateTo = this.state.utcTo;
    this.customInterval = this.state.interval;
    this.customOperatorInterval = this.state.operatorInterval;
    this.showCustomDateDialog = true;
  }

  shouldShowSum(
    trendChannel: ITrendChannelDetails,
    state: ISiteChannelTrendState
  ) {
    const { channelId } = trendChannel;
    const { availableChannels } = state;

    const channel = availableChannels.find(c => c.channelId == channelId);
    if (isNone(channel)) return false;

    switch (channel.channelType) {
      case ChannelTypes.UnknownWithSum:
      case ChannelTypes.Flow:
      case ChannelTypes.Dose:
        return true;
      default:
        return false;
    }
  }

  setCustomInterval(interval: number, intervalOperator: number) {
    this.customOperatorInterval = intervalOperator;
    this.customInterval = interval;
  }

  downloadGraphAsImage = () => {
    const { site } = this.state;
    if (!site) return;
    const filenamePrefix = `${site.siteId} - ${site.alias}`;
    this.eventAggregator.publish('downloadGraph', filenamePrefix);
  };

  printPage = () => window.print();

  toggleCheckedTrend = (trendChannel: ITrendChannelDetails) =>
    this.dispatch(
      toggleSelectedTrendChannels({
        ids: [trendChannel.trendKey],
        add: !trendChannel.selected
      })
    );

  toggleCheckedTrendForAll = (
    allIsChecked: boolean,
    trendChannels: ITrendChannelDetails[]
  ) =>
    this.dispatch(
      toggleSelectedTrendChannels({
        ids: trendChannels.map(tc => tc.trendKey),
        add: !allIsChecked
      })
    );

  get userSecurityLevel() {
    return getSession().currentUser.securityLevel
  }
  
  get hasActiveSecurityLevelFilter(): boolean {
    return this.state.securityLevel !== undefined;
  }
    
  async setSecurityLevel(level: string) {
    if (!level) return;
    switch (level) {
      case "nofilter":
        this.dispatch(securityLevelChanged(undefined));
        break;
      case "high":
        this.dispatch(securityLevelChanged(SecurityLevel.High))
        break;
      case "medium":
        this.dispatch(securityLevelChanged(SecurityLevel.Medium))
        break;
      case "low":
        this.dispatch(securityLevelChanged(SecurityLevel.Low))
        break;
      case "none":
        this.dispatch(securityLevelChanged(SecurityLevel.None))
        break;
    }
    this.refreshData();
  }
  
  async getInitialTrendGroupsAndData() {
    this.initialDataLoad = true;
    const siteChannels = await this.channelService.getSiteChannelsForSiteAsync(
      this.state.siteId
    );
    const nativeControllers = await this.controllerService.getControllersBySiteIdAsync(
      this.state.siteId
    );
    const lastSampleTimes = orderByPredicate(
      removeNoneFromArray(
        siteChannels.map(sc =>
          isNone(sc.lastSampleTime) ? undefined : new Date(sc.lastSampleTime)
        )
      ),
      r => r,
      'desc'
    );
    const toDate = lastSampleTimes.length > 0 ? lastSampleTimes[0] : new Date();
    setEndOfDay(toDate);
    const fromDate = new Date(toDate);
    setBeginningOfDay(fromDate);

    const showGaps = !nativeControllers.some(
      c =>
        !c.controllerType.toLowerCase().startsWith('ida') &&
        !c.controllerType.toLowerCase().startsWith('mt device')
    );

    this.dispatchShowGapsChanged(showGaps);

    await this.userTrendGroupService.getAll(this.state.siteId);
    this.loadChartData({ from: fromDate, to: toDate }, 5, 0, this.state.siteId);
    this.initialDataLoad = false;
  }

  deleteSelectedTrendChannels = async () => {
    this.userTrendChannelService
      .remove(
        this.state.trendChannels.filter(
          tc =>
            !isNone(
              this.state.selectedTrendChannelIds.find(
                sid => sid === tc.trendKey
              )
            )
        )
      )
      .then(() => this.refreshData());
  };

  editSelectedTrendChannels = () =>
    this.showTrendChannelEditor(this.state.selectedTrendChannelIds);

  showTrendChannelEditor(trendChannelIds: number[] | number | undefined) {
    if (trendChannelIds === undefined) {
      // New trendChannel
      this.dispatch(
        editTrendChannel({
          trendChannel: {
            visible: true,
            operatorId: 3,
            axis: {
              axisTypeId: 1,
              locationId: 0,
              autoScale: true,
              maxScale: 0,
              minScale: 0
            }
          },
          trendChannelIds: isNone(trendChannelIds)
            ? undefined
            : assureArray(trendChannelIds)
        })
      );
      return;
    }

    if (isArray(trendChannelIds) && trendChannelIds.length > 1) {
      const trendChannels = this.state.trendChannels.filter(
        tc => !isNone(trendChannelIds.find(tcId => tcId === tc.trendKey))
      );
      const commonOperatorId = distinct(trendChannels.map(tc => tc.operatorId));
      const commonLocationId = distinct(
        trendChannels.map(tc => tc.axis.locationId)
      );
      const commonAxisTypeId = distinct(
        trendChannels.map(tc => tc.axis.axisTypeId)
      );
      const commonAutoScale = distinct(
        trendChannels.map(tc => tc.axis.autoScale)
      );
      const commonVisible = distinct(trendChannels.map(tc => tc.visible));
      const axis: Partial<ITrendAxis> = {
        ...(commonAxisTypeId.length === 1
          ? { axisTypeId: commonAxisTypeId[0] }
          : {}),
        ...(commonLocationId.length === 1
          ? { locationId: commonLocationId[0] }
          : {}),
        ...(commonAutoScale.length === 1
          ? { autoScale: commonAutoScale[0] }
          : {}),
        maxScale: 0,
        minScale: 0
      };
      this.dispatch(
        editTrendChannel({
          trendChannel: {
            ...(commonVisible.length === 1
              ? { visible: commonVisible[0] }
              : {}),
            ...(commonOperatorId.length === 1
              ? { operatorId: commonOperatorId[0] }
              : {}),
            axis
          },
          trendChannelIds: isNone(trendChannelIds)
            ? undefined
            : assureArray(trendChannelIds)
        })
      );
      return;
    }

    const trendChannelIdsArray = assureArray(trendChannelIds);
    const trendChannelId = trendChannelIdsArray[0];

    const trendChannel = this.state.trendChannels.find(
      tc => tc.trendKey === trendChannelId
    );
    if (!trendChannel) return;

    this.dispatch(
      editTrendChannel({ trendChannel, trendChannelIds: trendChannelIdsArray })
    );
  }

  async saveTrendChannel() {
    if (
      !this.state.trendIdsToEdit &&
      (this.state.currentlyEditingTrend &&
        !this.state.currentlyEditingTrend.channelId)
    ) {
      this.notificationService.notify({
        type: 'CUSTOM',
        level: 'error',
        text: 'You have to select channel to add as a trend',
        timestamp: new Date().toString(),
        acknowledged: false
      });
      return;
    }

    const activeTrend = this.state.currentlyEditingTrend;
    if (isNone(activeTrend)) return;

    const defaultAxis: ITrendAxis = {
      autoScale: true,
      axisTypeId: 0,
      locationId: 0,
      maxScale: 0,
      minScale: 0
    };

    if (isNone(this.state.trendIdsToEdit))
      await this.userTrendChannelService.add(this.state.currentTrendGroup!, {
        channelId: 0,
        color: '#ccc',
        unitId: 0,
        siteChannelId: 0,
        operatorId: 0,
        siteId: 0,
        trendGroupId: 0,
        trendKey: 0,
        visible: true,
        ...activeTrend,
        stats: {
          count: 0,
          delta: 0,
          max: 0,
          mean: 0,
          min: 0,
          sum: 0
        },
        axis: {
          ...defaultAxis,
          ...activeTrend.axis
        },
        data: []
      });
    else
      await this.userTrendChannelService
        .update(
          removeNoneFromArray(
            this.state.trendIdsToEdit.map(id => {
              const existingTrendChannel = this.state.trendChannels.find(
                tc => tc.trendKey === id
              );
              const returnus = existingTrendChannel
                ? {
                    ...existingTrendChannel,
                    color: !isNone(activeTrend.color)
                      ? activeTrend.color
                      : existingTrendChannel.color,
                    operatorId: !isNone(activeTrend.operatorId)
                      ? activeTrend.operatorId
                      : existingTrendChannel.operatorId,
                    visible: !isNone(activeTrend.visible)
                      ? activeTrend.visible
                      : existingTrendChannel.visible,
                    axis: {
                      ...existingTrendChannel.axis,
                      autoScale:
                        !isNone(activeTrend.axis) &&
                        !isNone(activeTrend.axis.autoScale)
                          ? activeTrend.axis.autoScale
                          : existingTrendChannel.axis.autoScale,
                      axisTypeId:
                        !isNone(activeTrend.axis) &&
                        !isNone(activeTrend.axis.axisTypeId)
                          ? activeTrend.axis.axisTypeId
                          : existingTrendChannel.axis.axisTypeId,
                      locationId:
                        !isNone(activeTrend.axis) &&
                        !isNone(activeTrend.axis.locationId)
                          ? activeTrend.axis.locationId
                          : existingTrendChannel.axis.locationId,
                      maxScale:
                        (isSomething(activeTrend.axis) 
                            && isSomething(activeTrend.axis.maxScale)
                            && isFiniteNumber(activeTrend.axis.maxScale) 
                          ? ensureNumber(activeTrend.axis.maxScale)
                          : existingTrendChannel.axis.maxScale || 100),
                      minScale:
                        (isSomething(activeTrend.axis)
                            && isSomething(activeTrend.axis.minScale)
                            && isFiniteNumber(activeTrend.axis.minScale)
                          ? ensureNumber(activeTrend.axis.minScale)
                          : existingTrendChannel.axis.minScale || 0)
                    },
                    data: []
                  }
                : undefined;

              return returnus;
            })
          )
        )
        .catch(() => {
          this.notificationService.notify({
            type: 'CUSTOM',
            level: 'error',
            text: 'Error saving graph trend',
            timestamp: new Date().toString(),
            acknowledged: false
          });
        });

    this.loadChartData(
      {
        from: this.state.utcFrom,
        to: this.state.utcTo
      },
      this.state.interval,
      this.state.operatorInterval,
      this.state.siteId
    );
  }

  trendToogleShowGaps() {
    this.dispatchShowGapsChanged(!this.state.showGaps);
    this.refreshData();
  }

  trendToogleShowRaw() {
    this.dispatchShowRawChanged(!this.state.showRaw);
    this.refreshData();
  }

  refreshData() {
    if (!this.state.trendChannels.length) return;
    this.loadChartData(
      {
        from: this.state.utcFrom,
        to: this.state.utcTo
      },
      this.state.interval,
      this.state.operatorInterval,
      this.state.siteId
    );
  }

  async saveTrendGroup() {
    await this.userTrendGroupService.add(this.state.siteId, {
      name: this.state.currentlyEditingTrendGroup!.name,
      siteId: 0,
      trendChannels: emptyArray,
      userTrendGroupId: -1,
      active: false
    });
    this.refreshData();
  }

  async loadChartData(
    range: IDateRange,
    interval: number,
    operatorInterval: number,
    siteId?: number
  ) {
    const { showGaps, showRaw } = this.state;
    
    if (this.showCustomDateDialog) this.showCustomDateDialog = false;
    if (this.state.currentTrendGroup) {
      const { trendChannels } = this.state;

      if (trendChannels.length === 0 || siteId === undefined) return;

      this.historianService.getTrendData(
        range,
        {
          interval,
          operatorInterval,
          trendChannels: trendChannels
            .map<IDataProcessChannel>(tc => ({
              channelId: tc.channelId,
              operatorId: tc.operatorId,
              siteChannelId: tc.siteChannelId,
              trendKey: tc.trendKey,
              securityLevel: tc.securityLevel
            })),
          showGaps,
          showRaw
        },
        this.state.currentTrendGroup.userTrendGroupId,
        this.state.timezone
      );

      if (this.state.showAlarms) {
        this.historianService.getUserAlarmLogs(
          range,
          siteId,
          this.state.trendChannels.map(tc => tc.siteChannelId)
        );
      }
    }
  }

  rangeChanged([from, to]: Date[]) {
    if (isNone(from) || isNone(to)) return;

    if (this.dateRangeChanged)
      this.dateRangeChanged(
        from,
        to,
        this.state.interval,
        this.state.operatorInterval
      );
  }

  selectDateRangeChanged(item: { name: string; value: DateRange }) {
    let utcFrom = this.state.utcFrom;
    let utcTo = this.state.utcTo;
    let interval = this.state.interval;
    let operatorInterval = this.state.operatorInterval;

    switch (item.value) {
      case DateRange.Today:
        utcFrom = new Date();
        utcTo = new Date();
        interval = 5;
        operatorInterval = 0;
        break;

      case DateRange.Yesterday:
        utcFrom = new Date();
        utcFrom.setDate(utcFrom.getDate() - 1);
        utcTo = new Date();
        utcTo.setDate(utcTo.getDate() - 1);
        interval = 5;
        operatorInterval = 0;
        break;

      case DateRange.Last7days:
        utcTo = new Date();
        utcFrom = new Date();
        utcFrom.setDate(utcFrom.getDate() - 6);
        interval = 1;
        operatorInterval = 1;
        break;

      case DateRange.Last30Days:
        utcTo = new Date();
        utcFrom = new Date();
        utcFrom.setDate(utcFrom.getDate() - 29);
        interval = 1;
        operatorInterval = 1;
        break;

      case DateRange.ThisMonth:
        utcTo = new Date();
        utcTo.setMonth(utcTo.getMonth() + 1); // add one month
        utcTo.setDate(1);
        utcTo.setDate(utcTo.getDate() - 1); // subtract one day

        utcFrom = new Date();
        utcFrom.setDate(1); // set it to the first of month

        interval = 1;
        operatorInterval = 1;
        break;

      case DateRange.LastMonth:
        utcTo = new Date();
        utcTo.setDate(1);
        utcTo.setDate(utcTo.getDate() - 1);

        utcFrom = new Date(utcTo);
        utcFrom.setDate(1); // subtract 1 day
        interval = 1;
        operatorInterval = 1;
        break;
    }

    setBeginningOfDay(utcFrom);
    setEndOfDay(utcTo);

    this.dateRangeChanged(utcFrom, utcTo, interval, operatorInterval);
  }

  dateRangeChanged(
    utcFrom: Date,
    utcTo: Date,
    interval: number,
    operatorInterval: number
  ) {
    this.loadChartData(
      { from: utcFrom, to: utcTo },
      interval,
      operatorInterval,
      this.state.siteId
    );
  }

  trendChannelColorChanged(newColor: string) {
    this.dispatch(setTrendChannelColor(newColor));
  }

  dispatchShowGapsChanged = (showGaps: boolean) => {
    if (!this.state.trendChannels.length) return;
    this.dispatch(showGapsChanged(showGaps));
  };

  dispatchShowRawChanged = (showRaw: boolean) => {
    if (!this.state.trendChannels.length) return;
    this.dispatch(showRawChanged(showRaw));
  };

  dispatchSelectedChannelChanged = (channelId: number) => {

    const channel = this.state.availableChannels.find(
      c => c.channelId == channelId
    );

    if (channel !== undefined) this.dispatch(selectedChannelChanged(channel));
  };

  dispatchOperatorChanged = (selectedValue: string) =>
    this.dispatch(selectedOperatorChanged(ensureNumber(selectedValue)));

  dispatchVisibilityChanged = (visible: boolean) =>
    this.dispatch(selectedVisibilityChanged(visible));

  dispatchAxisTypeChanged = (selectedValue: string) =>
    this.dispatch(selectedAxisTypeChanged(ensureNumber(selectedValue)));

  dispatchAxisLocationChanged = (selectedValue: string) =>
    this.dispatch(selectedAxisLocationChanged(ensureNumber(selectedValue)));

  dispatchAxisAutoScaleChanged = (autoScale: boolean) =>
    this.dispatch(selectedAxisAutoScaleChanged(autoScale));

  dispatchToggleExpandMenuOnEntity = (entityId: number) =>
    this.dispatch(toggleExpandMenuOnEntity(entityId));

  dispatchAxisPropertyChanged = (property: string, value: string) =>
    this.dispatch(axisPropertyChanged({ property, value }));

  dispatchTrendGroupPropertyChanged = (property: string, value: string) =>
    this.dispatch(trendGroupPropertyChanged({ property, value }));

  dispatchRemoveTrendGroup = async (groupId: number) => {
    this.currentGroup = undefined;
    await this.userTrendGroupService.remove(this.state.siteId, groupId);
    await this.userTrendGroupService.getAll(this.state.siteId);
  };

  dispatchTrendGroupChanged = (group: ITrendGroup) => {
    this.dispatch(trendGroupChanged(group));
    this.loadChartData(
      { from: this.state.utcFrom, to: this.state.utcTo },
      this.state.interval,
      this.state.operatorInterval,
      this.state.siteId
    );
    this.userTrendGroupService.setActive(group.siteId, group.userTrendGroupId);
  };

  cancelTrendChannel = () => this.dispatch(toggleTrendEditDialog());

  dispathToggleTrendNewGroupDialog = () =>
    this.dispatch(toggleTrendNewGroupDialog());

  trendChannelRemoved = (trendChannel: ITrendChannel) =>
    this.userTrendChannelService.remove([trendChannel]);

  removeDefaultTrendChannel = (trendChannel: ITrendChannel) =>
    this.userTrendChannelService.removeDefault([trendChannel]);

  trendMoveLeft() {
    if (!this.state.trendChannels.length) return;
    const delta = this.state.utcTo.getTime() - this.state.utcFrom.getTime();

    const utcTo = new Date(this.state.utcFrom.getTime() - 1);
    const utcFrom = new Date(utcTo.getTime() - delta);

    this.dateRangeChanged(
      utcFrom,
      utcTo,
      this.state.interval,
      this.state.operatorInterval
    );
  }

  trendMoveRight() {
    if (!this.state.trendChannels.length) return;
    const delta = this.state.utcTo.getTime() - this.state.utcFrom.getTime();

    const utcFrom = new Date(this.state.utcTo.getTime() + 1);
    const utcTo = new Date(utcFrom.getTime() + delta);

    this.dateRangeChanged(
      utcFrom,
      utcTo,
      this.state.interval,
      this.state.operatorInterval
    );
  }

  confirmRemoval(trendGroupToDelete: ITrendGroup) {
    this.currentGroup = trendGroupToDelete;
  }

  dispatchExportPropertyChanged = (
    property: string,
    value: string | number | Date
  ) => this.dispatch(exportPropertyChanged({ property, value }));
  dispatchExportDatePropertyChanged = (property: string, value: Date) =>
    this.dispatch(exportDatePropertyChanged({ property, value }));

  dispatchExportToggleSiteChannel = (siteChannelId: number, checked: boolean) =>
    this.dispatch(exportToggleSiteChannel({ siteChannelId, checked }));

  dispatchToggleExport = () =>
    this.dispatch(toggleExport(this.state.trendChannels));

  exportGraphData = (site: ISite, definition: IGraphExport) =>
    this.graphExportService.export(site, definition);

  dispatchExportToggleAllSiteChannels = (
    availableChannels: ISiteChannel[],
    checked: boolean
  ) => {
    const toggleAll = !checked;

    const ids = toggleAll
      ? availableChannels.map(c => c.siteChannelId)
      : emptyArray;

    this.dispatch(exportToggleAllSiteChannels({ ids, checked: toggleAll }));
  };

  toggleAlarmsDisplay() {
    const currentlyShowingAlarms = this.state.showAlarms;
    this.dispatch(showAlarmsChanged({ value: !currentlyShowingAlarms }));
    if (!currentlyShowingAlarms) {
      this.refreshData();
    }
  }

  setTrendVisibility = async (
    trendChannel: ITrendChannel,
    visible: boolean
  ) => {
    // this.userTrendChannelService.remove([trendChannel]);
    trendChannel.visible = visible;

    await this.userTrendChannelService.update([trendChannel]);
  };

  getDisplayForChannel = (channel: ISiteChannel) => {
    return channel.alias || channel.code;
  };
}
