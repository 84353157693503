/**
 * This is a copy of the old colorrotator from YT2 translated into TS
 */
export class ColorRotator {
  private _index = 0;
  private _colorList = [
    '#FF0000', //"Red";
    '#008000', //"Green";
    '#0000FF', //"Blue";
    '#FFC0CB', //"Pink";
    '#FFFF00', //"Yellow";
    '#00FFFF', //"Cyan";
    '#2E8B57', // "SeaGreen";
    '#800080', //"Purple";
    '#ADD8E6', //"LightBlue";
    '#DB7093' //"PaleVioletRed";
  ];

  get color() {
    const col = this._colorList[this._index];
    this._index++;
    if (this._index > 9) this._index = 0;

    return col;
  }
}
