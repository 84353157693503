import { customElement, bindable, autoinject } from 'aurelia-framework';
import {
  IYAxis,
  createYAxisFromTrendChannel,
  createSharedYAxisFromTrendChannel
} from '../../../../../../models';
import {
  formatDate,
  toObject,
  ensureDate,
  isNone,
  emptyArray,
  orderByPredicate,
  displayMeasurement,
  addGlobalResize,
  removeGlobalResize,
  formatDateTime,
  formatTime,
  convertToTimezone
} from '../../../../../../utility';
import {
  ITrendChannelDetails,
  IAlarmLogExtended
} from '../../../../../../interfaces';
import {
  trendAxisTypes,
  trendAxisLocations
} from '../../../../../../utility/trendHelpers';
import trottle from 'lodash.throttle';
import { computedFrom } from 'aurelia-binding';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { BlobService } from '../../../../../../services';
import { I18N } from 'aurelia-i18n';
import { ECharts, EChartOption, init } from 'echarts/lib/echarts';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/dataZoom';
import 'echarts/lib/component/markArea';
import './graph-trend.css';

function getAlarmTypeAsString(alarmType: number) {
  switch (alarmType) {
    case 1:
      return 'UI_SiteDetails_Alarms_Custom';
    case 2:
      return 'UI_SiteDetails_Alarms_LowLevel';
    case 3:
      return 'UI_SiteDetails_Alarms_PreLowLevel';
    case 4:
      return 'UI_SiteDetails_Alarms_Configuration';
    case 5:
      return 'UI_SiteDetails_Alarms_Offline';
    case 6:
      return 'UI_SiteDetails_Alarms_PercentCapacity30';
    case 7:
      return 'UI_SiteDetails_Alarms_Maintenance';
    case 8:
      return 'UI_SiteDetails_Alarms_DoseCalibration';
    case 9:
      return 'UI_SiteDetails_Alarms_SeriousDoseCalibration';
    case 10:
      return 'UI_SiteDetails_Alarms_BrokenSensor';
    default:
      return 'Unknown';
  }
}

@customElement('graph-trend')
@autoinject()
export class GraphTrendCustomElement {
  @bindable trendChannels: ITrendChannelDetails[];
  @bindable alarmLogs: IAlarmLogExtended[];
  @bindable utcFrom: Date;
  @bindable showGaps: boolean = false;
  @bindable utcTo: Date;
  @bindable isLoading: boolean = false;
  @bindable timezone: string;
  @bindable showAlarms: boolean = false;

  resizeHandler = trottle(() => this.updateChart(), 500);

  private chart: ECharts;
  private chartDiv: HTMLDivElement;
  private chartOption: EChartOption;

  private trendAxisTypesMap = toObject(trendAxisTypes, item => item.id);
  private trendAxisLocationsMap = toObject(trendAxisLocations, item => item.id);
  downloadGraphSubscription: Subscription;
  // private echartsMarkAreaClickHandlerRegistered = false;
  gridClick: boolean = false;

  constructor(
    eventAggregator: EventAggregator,
    private blobService: BlobService,
    private i18n: I18N
  ) {
    this.downloadGraphSubscription = eventAggregator.subscribe(
      'downloadGraph',
      (filename: string) => this.downloadChartAsImage(filename)
    );
    // Create initial chartOption
    this.chartOption = {
      backgroundColor: '#ffffff',
      animation: false,
    
      legend: {
        show: false,
        textStyle: {
          fontFamily: 'YaraMaxLF-Light'
        }
      },
      color: [],
      yAxis: [],
      series: [],
      textStyle: {
        fontFamily: 'YaraMaxLF-Light'
      },
      grid: { left: 60, right: 60, containLabel: true },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        },
        confine: true,
        formatter: (series: object[]) => {
          let format = '';
          let ts = new Date();
          let tsSet = false;
          const sortedSeries = orderByPredicate(
            series,
            (s: any) => {
              let tc = this.visibleTrendChannels.find(
                tc => s.seriesId === this.getSeriesId(tc)
              );
              if (!tc) return;
              else return tc.sortIndex;
            },
            'asc'
          );

          sortedSeries.forEach((s: any) => {
            if (s.data !== undefined && s.data.length === 2) {
              if (!tsSet) {
                ts = new Date(s.data[0]);
                tsSet = true;
              }

              const trendChannel = this.visibleTrendChannels.find(
                tc => s.seriesId === this.getSeriesId(tc)
              );
              if (!trendChannel) return;
              const unitName = trendChannel.unitName;
              const value = s.data[1] as number;
              if (!isNone(value))
                format += `<i class="fa fa-square" style="color:${this.htmlSafe(
                  trendChannel.color
                )}"></i> ${this.htmlSafe(
                  trendChannel.channelName
                )}: ${displayMeasurement(
                  value,
                  trendChannel.unit
                )} ${this.htmlSafe(unitName)}<br/>`;
            }
          });

          const dateString = formatDate(ts, true, '.', this.timezone);

          return dateString + '<br/>' + format;
        }
      },
      dataZoom: [
        {
          show: true,
          realtime: true,
          start: 0,
          end: 100
        },
        {
          type: 'inside',
          realtime: true,
          start: 0,
          end: 100
        }
      ]
    };
  }

  @computedFrom('trendChannels')
  get visibleTrendChannels() {
    return this.trendChannels.filter(tc => tc.visible);
  }
  @computedFrom('trendChannels')
  get hasData() {
    return !isNone(
      this.trendChannels.find(tc => tc.data && tc.data.length > 0)
    );
  }

  attached() {
    this.chart = init(this.chartDiv, undefined, { renderer: 'canvas' });

    if (this.trendChannels && this.trendChannels.length > 0) this.updateChart();

    addGlobalResize(this.resizeHandler);
  }

  htmlSafe(text: string) {
    if (!text) return '';

    let t = text.replace(/\</g, '&lt;');
    t = t.replace(/\>/g, '&gt;');

    return t;
  }

  downloadChartAsImage(filename: string) {
    if (!this.chart) return;
    const dataUrl = this.chart.getDataURL({
      backgroundColor: '#fff',
      type: 'png'
    });
    this.blobService.downloadFileFromUrl(dataUrl, `${filename}.png`);
  }

  trendChannelsChanged() {
    this.updateChart();
  }

  alarmLogsChanged() {
    this.updateChart();
  }

  showAlarmsChanged() {
    this.updateChart();
  }

  isLoadingChanged() {
    this.updateChart();
  }

  detached() {
    removeGlobalResize(this.resizeHandler);
    this.downloadGraphSubscription.dispose();
  }

  operatorRangeIdToChar(id: number) {
    switch (id) {
      default:
        return 'm';
      case 1:
        return 'h';
      case 2:
        return 'd';
      case 3:
        return 'w';
    }
  }

  showLoading() {
    if (this.chart) this.chart.showLoading();
  }

  getIntervalInMs(unit: string, interval: number): number {
    switch (unit.toLowerCase()) {
      case 'm':
        return interval * 60 * 1000;
      case 'h':
        return interval * 60 * 60 * 1000;
      case 'd':
        return interval * 24 * 60 * 60 * 1000;
      case 'w':
        return interval * 7 * 24 * 60 * 60 * 1000;
      default:
        throw Error('Unsupported unit type ' + unit);
    }
  }

  updateChart() {
    // Not initialized properly yet
    if (!this.visibleTrendChannels || !this.chart) return;

    if (this.isLoading) {
      this.showLoading();
      return;
    }

    const series = new Array<object>();
    const yaxis = new Array<IYAxis>();

    // Load into chartOption
    orderByPredicate(
      this.visibleTrendChannels,
      tc => tc.axis.axisTypeId,
      'desc'
    ).forEach(tc => {
      const seriesData = this.toDataSeries(tc);

      if (!tc.color || tc.color === '') tc.color = '#000';

      // Get existing or create new yAxis
      const axis: IYAxis = this.getYAxis(yaxis, tc, this.visibleTrendChannels);

      const serie = {
        name: this.getSeriesName(tc),
        id: this.getSeriesId(tc),
        type: 'line',
        lineStyle: {
          color: tc.color,
          type: 'solid',
          normal: {
            width: this.showGaps ? 0 : (tc.selected ? 4 : 2),
            color: tc.color
          }
        },
        emphasis: {
          itemStyle: {
            borderWidth: 10
          }
        },
        itemStyle: {
          borderWidth: 2,
          color: tc.color
        },
        yAxisIndex: axis.index,
        showSymbol: this.showGaps,
        symbolSize: 2,
        data: seriesData,
        zlevel: tc.selected ? 1000 : 0
      };

      series.push(serie);
    });

    if (this.showAlarms && this.alarmLogs) {
      const alarmMarkerSeries = {
        name: 'alarmMarkerSeries',
        id: 'alarmMarkerSeries',
        type: 'line',
        markArea: {
          silent: true,
          data: this.createEchartMarkAreasForAlarms(
            this.alarmLogs,
            this.utcFrom,
            this.utcTo
          ),
          label: { rotate: 90, offset: [10, 200], color: '#fff' }
        }
      };
      series.push(alarmMarkerSeries);
    }
    this.chart.resize();
    this.chart.clear();
    this.chart.hideLoading();

    // console.log('y-axises:', yaxis);

    // Only update if we have or have had something to draw
    if (
      series.length > 0 &&
      this.trendChannels &&
      this.trendChannels.length > 0 &&
      (series.length > 0 ||
        (this.chartOption.series && this.chartOption.series.length > 0))
    ) {
      // Clear axis and series, redraw chart
      this.chartOption.xAxis = this.getXAxis();
      this.chartOption.yAxis = yaxis;
      this.chartOption.grid = {
        left: Math.max(
          yaxis
            .filter(y => y.position === 'left')
            .reduce((cur, next) => cur + next.width, 0),
          30
        ),
        right: Math.max(
          yaxis
            .filter(y => y.position === 'right')
            .reduce((cur, next) => cur + next.width, 0),
          30
        )
      };
      this.chartOption.series = series;
      this.chart.setOption(this.chartOption);

      // Click handler for echart markAreas:
      // if (this.showAlarms && !this.echartsMarkAreaClickHandlerRegistered) {
      //     this.chart.on('click', (evt: any) => {
      //         if (evt.componentType === 'markArea') {
      //             const timeValueClicked = this.chart.convertFromPixel({ seriesIndex: evt.seriesIndex }, [evt.event.offsetX, evt.event.offsetY])[0];

      //             for (const interval of this.flatAlarmLogIntervals) {
      //                 const intervalStartTime = interval.start && interval.start.localTime.valueOf() || this.utcFrom.valueOf();
      //                 const intervalEndTime = interval.end && interval.end.localTime.valueOf() || this.utcTo.valueOf();

      //                 if (intervalStartTime <= timeValueClicked && timeValueClicked <= intervalEndTime) {
      //                     console.log('* Interval clicked: ' + JSON.stringify(interval));
      //                 }
      //             }
      //         }
      //     });
      //     this.echartsMarkAreaClickHandlerRegistered = true;
      // }
    }
  }

  getXAxis(): any {
    const min = ensureDate(this.utcFrom).getTime();
    const max = ensureDate(this.utcTo).getTime();
    const range = Math.abs(max - min);
    let splitNumber = 24;
    let maxInterval = 3600 * 1000 * 24 * 30;
    let showTime = false;

    if (range <= 2 * 24 * 60 * 60 * 1000) {
      const chartWidth = this.chart.getWidth();
      splitNumber = chartWidth > 1000 ? 24 : 12;
      maxInterval = chartWidth > 1000 ? 60 * 60 * 1000 : 60 * 60 * 2000;
      showTime = true;
    }
    else if (range < 7 * 24 * 60 * 60 * 1000) {
      splitNumber = Math.round(range / (24 * 60 * 60 * 1000));
      maxInterval = 3600 * 1000 * 24;
    }
    else if (range < 31 * 24 * 60 * 60 * 1000) {
      splitNumber = Math.round(range / (24 * 60 * 60 * 1000));
      maxInterval = 3600 * 1000 * 24 * 30;
    }


    const xAxis = {
      id: 'xaxis0',
      type: 'time',
      axisPointer: {
        label: { show: false }
      },
      splitNumber, // Number of days shown, hours shown etc
      maxInterval,
      min,
      max,
      axisLabel: {
        formatter: (value: any, index: number) =>
          showTime
            ? formatTime(value, this.timezone)
            : index === 0
            ? formatDateTime(value, 'dd.MM.yyyy', this.timezone) // show year for first item only
            : formatDateTime(value, 'dd.MM', this.timezone)
      }
    };

    return xAxis;
  }

  getSeriesId(tc: ITrendChannelDetails): string {
    return tc.trendKey.toString();
  }

  getSeriesName(tc: ITrendChannelDetails): string {
    return tc.channelName; // + ' (' + tc.operator.interval + tc.operator.intervalUnit + ')';
  }

  getYAxis(
    yaxis: IYAxis[],
    tc: ITrendChannelDetails,
    allTc: ITrendChannelDetails[]
  ): IYAxis {
    // If separate axis, or first axis or no axis on location create new axis
    if (this.trendAxisTypesMap[tc.axis.axisTypeId].value === 'shared') {
      // If shared, look for one with same locaton and scale
      const existingAxis = yaxis.find(
        y =>
          (y.position ===
            this.trendAxisLocationsMap[tc.axis.locationId].value &&
            tc.axis.autoScale === true) ||
          (tc.axis.autoScale !== true &&
            y.max === tc.axis.maxScale &&
            y.min === tc.axis.minScale)
      );

      if (existingAxis !== undefined) return existingAxis;
    }

    // Create new axis if no suitable existing has been found
    const axisesOnSameSide = yaxis.filter(
      y => y.position === this.trendAxisLocationsMap[tc.axis.locationId].value
    );
    //let axisesOnSameSideHasSharedAxis = axisesOnSameSide.find(a => a.name === '');
    const isSharedAxis = tc.axis.axisTypeId === 1;
    const anySharedAxis = allTc.findIndex(tc => tc.axis.axisTypeId === 1) >= 0;

    //If we don't have any shared axis (only 'own' add an offset for first axis to prevent name from being inside graph area). This works on both left and right axis'es
    const offset = axisesOnSameSide.reduce((cur, next) => cur + next.width, !anySharedAxis ? 40 : 0);    
    const name = isSharedAxis
      ? ''
      : tc.channelName + (tc.unitName ? ` (${tc.unitName})` : '');

    //For a shared axis find the longest string for any of the channels
    let axis = undefined;

    if (isSharedAxis) {
      //Get all trend chnnels with shared axis on same side as tc
      let sharedTrendChannels = allTc.filter(
        ch =>
          ch.axis.axisTypeId === 1 && ch.axis.locationId === tc.axis.locationId
      );
      axis = createSharedYAxisFromTrendChannel(
        tc,
        name,
        yaxis.length,
        offset,
        sharedTrendChannels
      );
    } else {
      axis = createYAxisFromTrendChannel(tc, name, yaxis.length, offset);
    }

    yaxis.push(axis);

    return axis;
  }

  // Note that status.sum is not the pure calculated sum, but the sum of the hourly averages
  toDataSeries(tc: ITrendChannelDetails): object[] {
    return isNone(tc.data)
      ? emptyArray
      : tc.data.map(point => [point.ts, point.v]);
  }

  createEchartMarkAreasForAlarms(
    alarmLogs: IAlarmLogExtended[],
    utcFirstTime: Date,
    utcLastTime: Date
  ) {
    // Convert each IAlamLogInterval to an echarts definition of one area within a whole markArea:
    let translatedAlarmNames: Record<number, string> = {};
    for (let log of alarmLogs)
      if (translatedAlarmNames[log.alarmType] === undefined)
        translatedAlarmNames[log.alarmType] = this.i18n.tr(
          getAlarmTypeAsString(log.alarmType)
        ); // Only translate each alarm name once due to speed concerns

    return alarmLogs.map(log => [
      {
        name: `${
          log.alarmName
            ? this.htmlSafe(log.alarmName)
            : translatedAlarmNames[log.alarmType]
        }`,
        xAxis: log.triggered
          ? convertToTimezone(this.timezone, log.triggered).toJSDate()
          : convertToTimezone(this.timezone, utcFirstTime).toJSDate()
      },
      {
        xAxis: log.reset
          ? convertToTimezone(this.timezone, log.reset).toJSDate()
          : convertToTimezone(this.timezone, utcLastTime).toJSDate()
      }
    ]);
  }
}
