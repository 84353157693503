import { requests } from '../config';
import { HttpClient } from 'aurelia-fetch-client';
import { autoinject, TaskQueue } from 'aurelia-framework';
import { getLogger } from 'aurelia-logging';
import { saveBlob, formatDate } from '../utility';
import { BaseService } from './baseService';
import { IGraphExport } from '../interfaces/entity/iGraphExport';
import { getTrendExport } from '../actions';
import { ISite } from '../interfaces';

@autoinject()
export class GraphExportService extends BaseService {
  constructor(httpClient: HttpClient, taskQueue: TaskQueue) {
    super(httpClient, getLogger(GraphExportService.name), taskQueue);
  }

  export = async (site: ISite, definition: IGraphExport) => {
    this.store.dispatch(getTrendExport.started({ siteId: site.siteId }));

    try {
      const file = await this.httpClient.fetch(
        requests.graphExport(site.siteId, definition)
      );

      const fileExtension = definition.format == 0 ? 'csv' : 'xlsx';

      saveBlob(
        await file.blob(),
        `${site.alias}_${formatDate(new Date(), true, '-')}.${fileExtension}`
      );

      this.store.dispatch(
        getTrendExport.done({
          params: { siteId: site.siteId },
          result: undefined
        })
      );
    } catch (error) {
      this.store.dispatch(
        getTrendExport.failed({
          params: { siteId: site.siteId },
          error: 'UI_GraphTrend_Export_InfoText_Error'
        })
      );
    }
  };
}
